@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
:root {
    --main-bg: #07070a;
    --text-color: #fff;
    --theme-color: #7456d0;
    --theme-secondary-color: rgba(116, 86, 208, 0.25);
    --theme-bg-color: rgba(56, 75, 221, 0.15);
    --dark-bg: #0f0f0f;
}

html, body, * {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

body {
    background: var(--main-bg);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}

* ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
}

* ::-webkit-scrollbar-thumb {
    background: var(--theme-color);
    border-radius: 8px;
}

* ::-webkit-scrollbar-track {
    background: var(--dark-bg);
}

*, *::before, *::after {
    box-sizing: border-box;
}

select option {
    color: black!important;
}

.pf-theme path {
    fill: var(--theme-color);
}

.pf-theme {
    margin-top: 1.6px;
}

//navbar
.storefront-navbar {
    display: flex;
    justify-content: space-between;
    height: 120px;
    align-items: center;
}

.storefront-navbar-branding:first-child {
    margin-right: auto;
}

.storefront-navbar-right:last-child {
    margin-left: auto;
}

.storefront-navbar > * {
    display: flex;
    flex: 1;
}

.storefront-navbar-right {
    justify-content: right;
}

.storefront-navbar-right:last-child {
    margin-right: 0;
}

.storefront-navbar-options {
    justify-content: center;
}

.storefront-navbar-branding-img {
    height: 2.5rem;
}

.storefront-navbar-branding-img:hover {
    cursor: pointer;
}

.storefront-navbar-branding-text {
    font-size: 28px;
    font-weight: 700;
}

.storefront-navbar-options {
    // flex: 1;
    // margin-left: 24px;
    // margin-left: 260px;
}

.storefront-navbar-option {
    color: #fff;
    text-decoration: none;
    line-height: 24px;
    margin: 0 8px;
    font-size: 15px;
    font-weight: 500;
}

.sno-image {
    display: flex;
    align-items: center;
}

.sno-image > img {
    width: 18px;
    margin-left: 8px;
}

.storefront-navbar-option-active {
    color: var(--theme-color);
    font-weight: 700;
}

.btn {
    border: none;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 600;
    padding: 6px 8.5px;
    border-radius: 8px;
    border: 1.5px solid transparent;
}

button * {
    text-decoration: none;
}

.btn-snb {
    padding: 10px 15px;
    font-size: 13px;
    background: var(--theme-color);
    margin: 0 8px 0 8px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.btn-snb > svg {
    margin-right: 8px;
}

.btn-snb-outline {
    background: transparent;
    border: 1.5px solid var(--theme-color);
    color: var(--theme-color);
}

.btn-snb-outline-none {
    background: transparent;
    border: 1.5px solid transparent;
    color: var(--theme-color);
}

.btn-snb:hover:not(:disabled) {
    cursor: pointer;
}

.storefront-index-hero {
    text-align: center;
}

.storefront-index-hero-img {
    width: 100%;
    border-radius: 6px;
    margin-top: 36px;
    // margin-bottom: 36px;
}

.storefront-index-hero-title {
    font-size: 30px;
    display: block;
    font-weight: 700;
}

.storefront-index-hero-text {
    color: #999;
    font-size: 17.5px;
    margin-top: 8px;
}

.storefront-index-search {
    display: flex;
    margin-top: 36px;
    width: 100%;
}

.storefront-search-container {
    display: flex;
    width: 100%;
}

.storefront-search-container > .storefront-input-container:last-of-type {
    margin-left: 2%;
}

.storefront-input-container {
    background: var(--dark-bg);
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    height: calc(1.5em + 1.5rem + 2px);
    border-radius: .5rem;
    border: 1px solid var(--theme-color);
    width: 49%;
    line-height: 1.5;
    padding: 0 1.25rem;
    display: flex;
}

.storefront-input-icon {
    color: var(--theme-color);
    padding: .75rem 0;
    width: 15px;
}

.storefront-input {
    margin-left: 1.25rem;
    padding: .75rem 0;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 14.5px;
    width: calc(100% - 15px);   
}

.storefront-input::placeholder {
    color: #fff;
    opacity: 1;
}

.storefront-search-input::placeholder {
    color: #fff;
}

.storefront-category-select {
    background: var(--dark-bg);
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    padding: .75rem 1.25rem;
    height: calc(1.5em + 1.5rem + 2px);
    border-radius: .5rem;
    font-size: 14.5px;
    margin-left: 12px;
    border: 1px solid var(--theme-color);
    width: 49%;
    color: #fff;
    line-height: 1.5;
}

.storefront-items > .storefront-items-row:first-of-type {
    margin-top: 36px;
}

.storefront-items-row > .storefront-item:first-of-type {
    margin-left: 0;
}

.storefront-items-row > .storefront-item:last-of-type {
    margin-right: 0;
}

.storefront-items-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.storefront-items-row > .storefront-item:first-of-type {
    // margin-left: 0;
}

.storefront-item {
    background: var(--dark-bg);
    width: 275px;
    border-radius: 6px;
    transition: .35s;
    border: 1px solid transparent;
    // margin-left: 2.66%;
    margin: 5px 7.5px;
}

.storefront-item:hover {
    // cursor: pointer;
    // background: rgba(34, 34, 34);
    // transition: .35s;
}

.storefront-item-image {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.storefront-item-content {
    padding: 12px;
    padding-top: 6px;
}

.storefront-item-name {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
}

.storefront-item-price {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 4px;
}

.storefront-item-button {
    background: var(--theme-color);
    border: 1px solid var(--theme-color);
    width: 100%;
    text-transform: capitalize;
    letter-spacing: normal;
    padding: 7px 8px;
    border-radius: 5px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    line-height: 1.5;
    font-size: .925rem;
    margin-top: 8px;
}

// .storefront-item-btn-price {
//     color: rgba(255, 255, 255, .95);
//     border-left: 2px solid rgba(255, 255, 255, .2);
//     padding-left: 8px;
//     margin-left: 8px;
// }

.btn:hover:not(:disabled) {
    cursor: pointer;
}

.storefront-item-button {
    transition: .25s;
}

.storefront-item-button:hover {
    transition: .25s;
    // background: rgba(110, 46, 204, .75);
    background: var(--theme-color);
}

.storefront-error {
    width: 100%;
    text-align: center;
}

.storefront-error-icon {
    font-size: 38px;
    color: rgba(255, 0, 0, .95);
}

.storefront-error-title {
    font-size: 22px;
    font-weight: 700;
}

.storefront-error-text {
    font-size: 15px;
    color: rgba(255, 255, 255, .8);
}

.auth-form-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-form {
    width: 504px;
    padding: 24px;
    border-radius: 6px;
}

.auth-form > * {
    display: block;
}

.auth-form-title {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
}

.auth-form-inputs {
    margin-top: 18px;
}

.auth-form-input {
    width: 100%;
    border: none;
    background: var(--dark-bg);
    color: #fff;
    padding: 12px 16px;
    font-size: 18px;
    border-radius: .375rem;
    border: 1px solid var(--theme-color);
    font-size: .9375rem;
    margin-bottom: 14px;
}

.auth-form-inputs > .auth-form-input:last-of-type {
    margin-bottom: 0;
}

.auth-form-btn {
    background: var(--theme-color);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    transition: .25s;
    text-transform: none;
}

.auth-form-btn svg {
    margin-right: 8px;
}

.auth-form-btn:hover:not(:disabled) {
    background: rgba(116, 86, 208, .75);
    transition: .25s;
}

.auth-form-btn:disabled {
    background: rgba(116, 86, 208, .75);
    color: rgba(255, 255, 255, .75);
}

.btn-nrm {
    background: var(--theme-color);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: fit-content;
    padding: 8px 12px;
    font-size: 16px;
    transition: .25s;
    text-transform: none;
}

.dflx {
    display: flex;
    align-items: center;
}

.btn-nrm-sm {
    font-size: 14px;
}

.btn-nrm-xsm {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.btn-nrm svg {
    margin-right: 8px;
}

.btn-nrm:hover:not(:disabled) {
    background: rgba(116, 86, 208, .75);
    transition: .25s;
}

.btn-nrm:disabled {
    background: rgba(116, 86, 208, .75);
    color: rgba(255, 255, 255, .75);
}

.auth-form-sub {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.auth-form-sub a {
    color: var(--theme-color);
    text-decoration: none;
}

.auth-form-captcha {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-top: 14px;
}

.auth-form-error {
    background: #Be253a;
    color: #fff;
    padding: 12px;
    margin-top: 8px;
    border-radius: 6px;
}

.form-info {
    background: rgba(52, 120, 253, .5);
    border: 1px solid var(--theme-color);
    margin-top: 8px;
    padding: 12px;
    border-radius: 8px;
    color: var(--theme-color);
}

//user dashboard
.userhome {
    width: 100%;
}

.user-home-hero {
    width: 100%;
    display: flex;
}

.user-home-hero-nav {
    width: 49%;
    margin-left: 1%;
}

.userhome-hero-item {
    width: 100%;
    height: 100%;
    background: var(--dark-bg);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    transition: .25s;
}

.user-home-hero-nav > .userhome-hero-item:first-of-type {
    margin-bottom: 16px;
}

.userhome-hero-item:hover {
    cursor: pointer;
    border: 2px solid var(--theme-color);
    transition: .25s;
}

.user-home-hero-options {
    width: 100%;
    padding: 12px;
}

.user-home-hero-options > .btn {
    width: 48%;
}

.user-home-hero-options > .btn:last-of-type {
    margin-left: 4%;
}

.user-home-hero-content-vls {
    width: 100%;
    display: flex;
}

.user-home-hero-vls-container {
    margin-bottom: auto;
}

.user-home-vls {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 0;
    align-items: center;
}

.uh-vls-icon {
    color: var(--theme-color);
    font-size: 20px;
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uh-vls-title {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.uh-vls-value {
    margin-left: 6px;
    color: rgba(255, 255, 255, .35);
}

.user-home-hero-content {
    background: var(--dark-bg);
    border-radius: 8px;
    padding: 12px;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
}

.user-tickets-welcome {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.user-tickets-content {
    flex: 1;
}

.user-home-welcome {
    margin: 78px 0;
}

.user-home-welcome-title {
    font-size: 32px;
    font-weight: 700;
    padding: 0;
}

.user-home-welcome-title svg {
    color: var(--theme-color);
}

.text-underline {
    text-decoration: underline;
    text-decoration-color: var(--theme-color);
}

.user-home-welcome-text {
    color: #999;
    font-weight: 600;
    font-size: 14px;
    margin-top: -4px;
}

.user-home-hero > .userhome-hero-item:first-of-type {
    margin-left: 0;
}

.userhome-hero-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(255, 255, 255, .95);
}

.userhome-hero-value {
    color: var(--theme-color);
    font-size: 32px;
    font-weight: 700;
}

.userhome-hero-icon {
    position: absolute;
    z-index: 1;
    opacity: .45;
    font-size: 130px;
    color: rgba(0, 0, 0, .4);
    left: -25px;
}

.userhome-hero-content {
    position: relative;
    z-index: 50;
    padding: 28px;
}

.username-hero-link {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    transition: transform ease 300ms;
    display: flex;
    height: 40px;
    padding-top: 15px;
    align-items: center;
}

.username-hero-link > svg {
    margin-right: 8px;
    color: var(--theme-color);
}

.username-hero-link:hover {
    cursor: pointer;
    transform: translate(0, -10px);
}

.user-home-content {
    background: var(--dark-bg);
    margin-top: 36px;
    border-radius: 8px;
    margin-bottom: 36px;
}

.user-home-content-title {
    padding: 16px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 1px solid rgba(255, 255, 255, .05);
}

.user-home-content-title svg {
    margin-right: 12px;
}

.user-home-content-news {
    padding: 16px;
    min-height: 348px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #999;
    justify-content: center;
}

.storefront-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storefront-loading-full-page {
    height: calc(100vh - 328px);
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, .8)!important;
}

.ReactModal__Content {
    background: #121516!important;
    border: 1px solid transparent!important;
    width: 600px!important;
    color: #fffefe!important;
    border-radius: 8px!important;
}

.storefront-modal-img {
    width: 340px;
}

.storefront-modal-title {
    font-size: 22px;
    font-weight: 600;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.storefront-modal-content {
    padding: 20px;
}

.storefront-modal-description {
    color: #fffefe;
    font-size: 16px;
}

.storefront-product-tags {
    margin-top: 36px;
}

.storefront-product-tags-title {
    font-size: 18px;
}

.storefront-product-tags-container {
    margin-top: 4px;
    display: flex;
    width: 100%;
}

.storefront-product-tags-container > .storefront-product-tag:first-of-type {
    margin-left: 0;
}

.storefront-product-tag {
    width: fit-content;
    border-radius: 6px;
    padding: 8px;
    margin-left: 10px;
}

.storefront-modal-select {
    display: block;
    border: 1px solid var(--theme-color);
    margin-top: 16px;
}

.storefront-cart-item-options {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.storefront-quantity-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.storefront-cart-item .storefront-quantity-select {
    margin-right: 12px;
}

.storefront-quantity-control {
    display: flex;
    justify-content: space-between;
}

.storefront-quantity-control input {
    width: 60px;
    background: var(--dark-bg);
    border: none;
    color: #fff;
    padding: 4px;
    text-align: center;
    border-radius: 6px;
}

.storefront-quantity-btn {
    background: var(--dark-bg);
    margin: 0 4px;
    font-weight: 700;
    border-radius: 6px;
}

.storefront-modal-btn {
    margin-top: 10px;
}

.storefront-cart-pg {
    width: 100%;
    display: flex;
}

.storefront-cart {
    padding: 0 24px 0 0;
    width: 50%;
}

.storefront-checkout {
    height: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: fit-content;
    border-radius: 8px;
    padding-top: 0;
}

.checkout-input {
    background: var(--dark-bg);
}

.storefront-cart-title {
    font-size: 22px ;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid transparent;
}

.sft-products {
    color: rgba(255, 255, 255, .5);
    font-weight: 500;
}

.storefront-checkout-title {
    font-size: 22px;
    font-weight: 700;
}

.checkout-inputs {
    padding-top: 0;
    margin-top: 18px;
}

.checkout-inputs > .input-group > input {
    margin-top: 6px;
}

.checkout-inputs label {
    color: rgba(255, 255, 255, 0.6);
}

.storefront-checkout-use-account-balance, .storefront-checkout-payment-method {
    color: rgba(255, 255, 255, 0.6);
}

.storefront-checkout-payment-methods > .storefront-checkout-payment-method:first-of-type {
    margin-top: 6px;
}

.storefront-checkout-payment-method {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    background: var(--dark-bg);
    margin-top: 12px;
    color: #fff;
    justify-content: space-between;
}

.storefront-checkout-payment-method:hover {
    cursor: pointer;
}

.storefront-checkout-payment-method-selected {
    border: 2px solid var(--theme-color);
}

.storefront-checkout-payment-method-image {
    font-size: 26px;
    padding: 3px;
}

.storefront-checkout-payment-methods {
    margin-bottom: 24px;
}

.input-group label {
    margin-bottom: 2px;
}

.checkout-complete {
    justify-self: flex-end;
    margin-top: 18px;
}

.storefront-cart > .storefront-cart-item:first-of-type {
    margin-top: 0;
}

.storefront-cart-item {
    display: flex;
    flex-direction: column;
    padding: 18px;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    margin-top: 18px;
    background: var(--dark-bg);
}

.storefront-cart-item-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.storefront-cart-item-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.storefront-cart-image {
    height: 100px;
    border-radius: 8px;
    margin-top: 4px;
    border: 1px solid rgba(255, 255, 255, .05);
}

.ucvi-ic {
    font-size: 42px;
    color: var(--theme-color);
}

.storefront-cart-quantity-btn {
    background: var(--main-bg);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.storefront-cart-quality-control input {
    background: var(--main-bg);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.storefront-cart-quality-control > .storefront-cart-quantity-btn:first-of-type {
    margin-left: 0;
}

.storefront-cart-quality-control > .storefront-cart-quantity-btn:last-of-type {
    margin-right: 0;
}

.storefront-cart-content-title {
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    width: 95%;
}

.storefront-cart-content-quantity {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    align-self: flex-end;
    margin-top: 12px;
}

.storefront-cart-content-price {
    font-size: 24px;
    font-weight: 500;
    text-align: right;
    color: #fff;
    align-self: flex-end;
}

.footer {
    margin-top: 48px;
}

.storefront-cart-remove-btn {
    margin: 0;
    background: transparent;
    color: #e63757;
    font-size: 22px;
    align-self: flex-end;
    padding: 0;
}

.storefront-cart-remove-btn svg {
    margin-right: 8px;
}

.checkout-summary {
    margin-top: 4px;
}

.checkout-summary-text {
    display: flex;
    justify-content: space-between;
}

.checkout-total-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-total-title {
    font-size: 32px;
}

.checkout-total-amount {
    font-size: 700;
    font-size: 32px;
}

.input-group > label {
    padding-bottom: 4px;
}

.checkout-form-captcha {
    display: none;
}

.mt-2 {
    margin-top: 8px;
}

.mt-4 {
    margin-top: 12px;
}

.mt-18 {
    margin-top: 18px;
}

input[type="checkbox"] {
    appearance: none;
    background-color: var(--dark-bg);
    margin: 0;
    font: inherit;
    color: var(--dark-bg);
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid var(--dark-bg);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
  
input[type="checkbox"]::before {
    content: "";
    width: 0.95em;
    height: 0.95em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--theme-color);
}
  
input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.storefront-checkout-use-account-balance {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    margin-bottom: 18px;
}

.userorders-table {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 .5em;
}

.userorders-table th {
    text-align: left;
    padding: 12px 18px;
}

.userorders-table-status {
    text-transform: uppercase;
    font-size: 13.5px;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 6px;
    width: fit-content;
    padding: 6px 7px;
}

.userorders-table td {
    background: var(--dark-bg);
    margin: 0;
    padding: 12px 18px;
    margin-top: 1em;
    text-align: left;
    font-size: 15.5px;
}

.userorders-table > tbody > tr > td:first-of-type {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.userorders-table tbody > tr button {
    width: 100%;
    background: var(--theme-color);
}

.userorders-table > tbody > tr > td:last-of-type {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.userorders-table-status-processing {
    background: rgba(31, 122, 239, 0.3);
    border: 1px solid rgba(31, 122, 239, 0.8);
    color: rgba(31, 122, 239);
}

.userorders-table-status-awaiting_payment {
    background: rgba(255, 140, 0, 0.3);
    border: 1px solid rgba(255, 140, 0, 0.8);
    color: rgba(255, 140, 0);
}

.userorders-table-status-cancelled {
    background: rgba(255, 0, 0, 0.3);
    border: 1px solid rgba(255, 0, 0, 0.8);
    color: rgba(255, 0, 0);
}

.userorders-table-status-completed {
    background: rgba(0, 200, 0, 0.3);
    border: 1px solid rgba(0, 200, 0, 0.8);
    color: rgba(0, 215, 0);
}

.userorder-tracking {
    border-top: 1px solid rgba(255, 255, 255, .05);
    padding-top: 18px;
}

.userorder-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userorder-status-box {
    width: fit-content;
    display: flex;
    align-items: center;
    color: #999;
}

.userorder-status-box-content {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
}

.userorder-status-box-icon {
    font-size: 48px;
}

.userorder-status-arrow {
    color: #999;
    font-size: 32px;
}

.userorder-status-box-title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
}

.userorder-status-box-text {

}

.userorder-status-box-active {
    animation: flashing .7s ease-out infinite alternate;
    color: rgba(255, 255, 255);
}

.userorder-status-box-final {
    color: #fff;
    opacity: 1;
}

.userorder-payment-status-update {
    padding: 24px;
}

.userorder-payment-status-update-title {
    font-size: 28px;
    font-weight: 700;
}

.userorder-payment-status-update-title svg {
    margin-right: 4px;
}

.userorder-payment-status-update-text {
    font-size: 18px;
}

.userorder-crypto-view-pending-notif {
    background: var(--main-bg);
    padding: 18px;
    border-radius: 8px;
    font-weight: 600;
    margin-top: 6px;
}

.userorder-crypto-view-pending-notif svg {
    margin-right: 4px;
}

.userorder-content {
    background: var(--main-bg);
    padding: 18px;
    width: 100%;
    border-radius: 8px;
    height: fit-content;
    margin-top: 12px;
    overflow-y: scroll;
    white-space: "pre-wrap";
}

.btn-psu {
    margin-left: 2px;
    margin-top: 12px;
    border-radius: 8px;
}

.btn-psu svg {
    padding-right: 4px;
}

.capitalize {
    text-transform: capitalize;
}

.storefront-product-tag {
    padding: 6px;
}

@keyframes flashing {
  0% {
    color:#fff;
    opacity:.3
  }
  100% {
    color:#fff;
    opacity:1
  }
}
@-webkit-keyframes flashing {
  0% {
    color:#fff;
    opacity:.3
  }
  100% {
    color:#fff;
    opacity:1
  }
}

.userorder-status-box-completed {
    opacity: .8;
}

.userorder-data {
    display: flex;
    width: 100%;
}

.userorder-data-main {
    width: 50%;
    padding: 18px;
}

.userorder-data-other {
    width: 50%;
    padding: 18px;
}

.userorder-data-other-title {
    height: 74px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
}

.checkout-crypto-options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.checkout-crypto-back {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    background: var(--dark-bg);
    margin-top: 18px;
    color: #fff;
    color: var(--theme-color);
    transition: .25s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    font-size: 16px;
    width: 100%;
}

.checkout-crypto-option {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    background: var(--dark-bg);
    margin-top: 8px;
    margin-right: 8px;
    color: #fff;
    color: var(--theme-color);
    transition: .25s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    font-size: 16px;
    width: fit-content;
}

.checkout-crypto-option svg, .checkout-crypto-back svg {
    margin-right: 8px;
}

.checkout-crypto-option:hover, .checkout-crypto-back:hover {
    border: 2px solid var(--theme-color);
    background: rgba(56, 75, 221, 0.25);
    cursor: pointer;
    transition: .25s;
}

.checkout-crypto-option:hover > .userorder-crypto-option-title {
    color: var(--theme-color);
    transition: .25s;
}

.userorder-data-other-value {
    padding: 18px;
}

.userorder-data-other-value-title {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .35px;
    color: rgba(255, 255, 255, .55);
    font-weight: 500;
}

.userorder-data-other-value-content {
    font-size: 24px;
}

.userorder-crypto-select {
    display: flex;
    width: 100%;
    height: 74px;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.userorder-crypto-option {
    display: flex;
    align-items: center;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid transparent;
}

.userorder-crypto-option:hover {
    cursor: pointer;
}

.userorder-crypto-option-img {
    width: 26px;
    height: 26px;
    margin-right: 12px;
}

.userorder-crypto-option-title {
    font-weight: 500;
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
}

.userorder-crypto-option-selected {
    border-bottom: 1px solid #fff;
}

.userorder-crypto-option-selected div {
    color: #fff;
}

.userorder-crypto-view-image {
    border-radius: 12px;
    background: white;
    padding: 18px;
}

.userorder-crypto-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 18px;
    background: var(--dark-bg);
    border-radius: 8px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.userorder-crypto-view-content {
    width: 100%;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.userorder-crypto-view-title {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
}

.userorder-crypto-view-title-img {
    margin-right: 12px;
    width: 48px;
}

.userorder-crypto-view-value {
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.userorder-crypto-view-value-title {
    font-size: 17px;
    letter-spacing: .15;
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .95);
}

.userorder-crypto-view-value-text {
    font-size: 16px;
    background: var(--main-bg);
    width: fit-content;
    padding: 7px 9px;
    border-radius: 8px;
    color: rgba(255, 255, 255, .65);
    border: 2px solid transparent;
    transition: .25s;
}

.userorder-crypto-view-value-text:hover {
    cursor: pointer;
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
    background: rgba(56, 75, 221, 0.25);
    transition: .25s;
}

body {
    position: relative;
    min-height: 100vh;
}

body::after {
    content: "";
    display: block;
    height: 244px;
}

.footer {
    background: var(--dark-bg);
    padding: 18px;
    height: fit-content;
    color: #D9DBE1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 244px;
}

.footer-content {
    display: flex;
}

.footer-branding {
    width: 100%;
}

.footer-branding-content {
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.footer-branding-img {
    height: 2.5rem;
}

.footer-branding-txt {
    width: 100%;
    color: #D9DBE1;
    margin-top: 4px;
    font-size: 15px;
    line-height: 32px;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: center;
}

.footer-social-nav {
    display: flex;
}

.footer-social-logo {
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.footer-social-logo > img {
    width: 18px;
}

.footer-nav {
    margin: 16px 0;
    display: flex;
    justify-content: center;
}

.footer-nav > * {
    color: #D9DBE1;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0 6px;
    text-decoration: none;
}

.footer-btm {
    border-top: 1px solid rgba(255, 255, 255, 0.17);
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cart-empty {
    margin-top: 64px;
}

.cart-empty-icon {
    color: var(--theme-color);
    font-size: 36px;
}

.cart-empty-text {
    font-size: 22px;
    font-weight: 700;
}

.storefront-items {
    min-height: 780px;
}

.user-tickets-question {
    display: flex;
    margin-top: 32px;
}

.user-tickets-question-index {
    background: var(--dark-bg);
    border-radius: 50%;
    height: fit-content;
    font-size: 24px;
    font-weight: 600;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
}

.user-tickets-question-content {
    margin-left: 24px;
}

.user-tickets-question-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 12px;
}

.user-tickets-question-description {
    margin-top: -1px;
    color: rgba(255, 255, 255, .75);
    font-size: 16px;
}

.user-tickets-question-input {
    width: 580px;
    margin-top: 12px;
    background: var(--dark-bg);
    border: none;
    padding: 18px 22px;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
}

.user-tickets-question-input-select:hover {
    cursor: pointer;
}

.user-tickets-question-captcha {
    margin-top: 12px;
    margin-bottom: 12px;
}

.btn-smt-ticket {
    margin-left: 0;
    padding: 10px 12px;
    font-size: 18px;
}

.mt-0 {
    margin-top: 0!important;
}

.userticket-message-content {
    max-width: 48%;
    width: fit-content;
    flex-direction: column;
    display: flex;
}

.userticket-message { 
    width: fit-content;
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 17.5px;
    display: flex;
    flex-direction: column;
}

.userticket-message-container {
    width: 100%;
    display: flex;
    margin-top: 18px;
}

.userticket-message-container-user {
    justify-content: right;
}

.userticket-message-container-user > .userticket-message-content {
    text-align: right;
    align-items: flex-end;
}

.userticket-message-container-staff {
    justify-content: left;
}

.userticket-message-container-staff > .userticket-message-content {
    text-align: left;
    align-items: flex-start;
}
.userticket-message-container {
    width: 100%;
    display: flex;
    margin-top: 18px;
}

.userticket-message-user {
    background: var(--theme-color);
}

.userticket-message-info-text {
    color: rgba(255, 255, 255, .45);
    font-size: 16px;
    margin-top: 2px;
}

.userticket-message-staff {
    background: var(--dark-bg);
}

.userticket-messages {
    min-height: 350px;
}

.ticket-closed-alert {
    background: var(--dark-bg);
    padding: 14px 18px;
    font-size: 19.5px;
    border-radius: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
}

.ticket-closed-alert svg {
    margin-right: 8px;
}

.ticket-closed-alert a {
    color: var(--theme-color);
}

.lnk-txt {
    color: var(--theme-color);
    text-decoration: none;
    font-weight: 500;
}

.lnk-txt:hover {
    text-decoration: underline;
}

.w75 {
    width: 85%;
}

.userticket-send-message {
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 36px;
}

.userticket-send-message textarea {
    background: var(--main-bg);
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px 14px;
    color: #fff;
    margin-top: 12px;
}

.userticket-send-message-options {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 12px;
}

.userticket-send-message-options button {
    margin-right: 0;
}

.userticket-message-title {
    font-size: 20px;
    font-weight: 700;
}

.userticket-message-text {
    font-size: 16px;
    color: rgba(255, 255, 255, .75);
}

.userticket-message > * {
    display: block;
}

.secondary-dark-bg {
    background: var(--dark-bg);
}

.faq-title {
    text-align: center;
    margin-bottom: 54px;
}

.faq-title > .user-home-welcome-title {
    font-size: 34px;
}

.faq-title > .user-home-welcome-text {
    font-size: 16px;
    margin-top: 0;
}

.faq-questions {
    margin-bottom: 128px;
}

.faq-question {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 16px;
    background: var(--dark-bg);
    border-radius: 12px;
    padding: 14px;
    border: 1px solid transparent;
}

.faq-question-expanded {
    background: rgba(56, 75, 221, 0.25);
    border: 1px solid var(--theme-color);
}

.faq-question:hover {
    cursor: pointer;
}

.faq-question-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.faq-question-content {
    margin-top: 16px;
    font-size: 16px;
}

.faq-question-content a {
    color: var(--theme-color);
    text-decoration: none;
}

.faq-question-name {
    font-size: 16px;
    line-height: 18px;
}

.faq-question-icon {
    font-size: 16px;
}

.btn-nav {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    padding: 10px 15px;
    transition: all linear .2s;
    letter-spacing: normal;
    border-radius: 6px;
    background: var(--theme-color);
    display: inline-flex;
    align-items: center;
}

.btn-nav:not(.btn-nav-cart) svg {
    margin-right: 8px;
}

.btn-nav-secondary {
    background: rgba(116, 86, 208, 0.45);
}

.btn-nav-cart {
    padding: 10px 15px;
    background: var(--theme-bg-color);
    color: var(--theme-color);
    font-weight: 600;
}

.btn-nav-cart svg {
    margin-right: 8px;
}

.btn-nav-small {
    padding: 15px;
    background: var(--theme-bg-color);
}

.btn-nav-small svg {
    margin-right: 0;
}

// .btn-nav-bg {
//     background: rgba(255, 153, 0, 0.53);
//     border: 1px solid#ff9900;
// }

.btn-snb-tr {
    border: 1px solid transparent;
    background: transparent;
    padding: 5px 0;
}

.btn-pag {
    background: var(--dark-bg);
    border: 1px solid transparent;
    margin-left: 8px;
}

.btn-pag-active {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
}

.storefront-pages {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 48px;
}

.text-line {
    margin-top: 12px;
}

.storefront-announcement {
    background: var(--theme-bg-color);
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
    padding: 12px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    // margin-bottom: 18px;
}

.order-alert {
    background: var(--theme-color);
    color: #fff;
    padding: 14px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    border: 2px solid var(--theme-color);
    // margin-bottom: 18px;
}

.order-alert > svg {
    font-size: 38px;
    margin-right: 14px;
}

.order-alert-title {
    font-weight: 700;
    font-size: 18px;
}

.order-alert-text {
    font-size: 16px;
    margin-top: -4px;
}

.order-alert-sm {
    background: var(--theme-bg-color);
    color: var(--theme-color);
    padding: 14px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    // margin-bottom: 18px;
}

.order-alert-scnd {
    background: var(--theme-bg-color);
    color: var(--theme-color);
    border: 2px solid var(--theme-color);
}

.order-info {
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 8px;
    margin-top: 18px;
}

.userorder-crypto-view-smtxt {
    font-size: 14.5px;
    color: rgba(255, 255, 255, .55);
    margin-top: 18px;
}

.order-alert-sm svg {
    font-size: 28px;
    margin-right: 12px;
}

.user-alert {
    margin-bottom: 32px;
    background: var(--dark-bg);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    justify-content: space-between;
}

.user-alert-icon {
    font-size: 48px;
    height: fit-content;
    display: flex;
}

.user-alert-icon {
    fill: var(--theme-color);
}

.user-alert-content {
    flex: 1;
    margin-left: 16px;
}

.user-alert-title {
    font-size: 18px;
    font-weight: 700;
}

.user-alert-text {
    font-size: 14px;
}

.user-alert {
    justify-self: end;
}

.storefront-product-stock-container {
    width: 100%;
    padding-top: 18px;
    margin-top: 18px;
    border-top: 1px solid rgba(255, 255, 255, .1);
}

.cnt-btn {
    margin-top: 12px;
    width: 35%;
    margin-left: 32.5%;
}

.cnt-code {
    width: 75%;
    margin-left: 12.5%;
    background: var(--dark-bg);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.cnt-cpy-btn {
    width: fit-content;
    margin: 0;
}

.cnt-cpy-btn svg {
    margin-right: 8px;
}

.cnt-code-txt {
    padding: 16px;
    font-size: 16px;
    color: rgba(255, 255, 255, .8);
    text-transform: uppercase;
}

.cnt-step {
    margin-top: 24px;
}

.cnt-step-title {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, .9);
}

.cnt-step-text {
    color: rgba(255, 255, 255, .8);
}

.cnt-btn svg {
    margin-right: 8px;
}

.ReactModal__Content {
    max-height: 80%;
    overflow-y: scroll;
}

.storefront-navbar-mobile-toggle {
    display: none;
    font-size: 28px;
    margin-right: 18px;
    justify-content: right;
}

.storefront-navbar-mobile-content {
    display: none;
}

.storefront-navbar-mobile-toggle:hover {
    cursor: pointer;
}

.storefront-navbar-mobile-content a, .storefront-navbar-mobile-content button {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 8px;
    width: 100%;
    text-decoration: none;
}

.userorder-crypto-expand-icon {
    display: none;
}

.storefront {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-left: .75rem;
    padding-right: .75rem;
}

.storefront-container {
    margin-bottom: 48px;
}

*:focus {
    outline: none;
}

textarea:focus, input:focus{
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.orderfront {
    width: 75%;
    margin-left: 12.5%;
}

.disabled-alwayswhite, .disabled-alwayswhite:disabled {
    color: #fff!important;
}

.products-dropdown-value {
    justify-content: space-between;
    display: flex;
}

.products-dropdown-options {
    margin-bottom: 32px;
    background: var(--dark-bg);
    border-radius: 0.375rem;
    margin-top: -16px;
}

.hover-pointer:hover {
    cursor: pointer;
}

.products-dropdown-option-value {
    padding: 12px 16px;
}

.products-dropdown-option-value:hover {
    background: rgba(0, 0, 0, .5);
    cursor: pointer;
}

@media (min-width: 576px) {
    .storefront {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .storefront {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .storefront {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .storefront {
        max-width: 1140px;
    }
}

@media screen and (max-width: 850px) {
    .orderfront {
        width: 90%;
        margin-left: 5%;
    }

    .user-home-hero-content {
        width: 100%;
    }

    .footer-social-nav {
        width: 25%;
    }

    .user-home-hero-nav {
        width: 100%;
        margin-left: 0;
    }

    .userhome-hero-item {
        width: 100%;
        margin-left: 0;
    }
    
    .userorder-data-other {
        width: 100%;
    }

    .userorder-data-main {
        width: 100%;
    }

    .storefront-cart-title {
        align-items: center;
    }

    .storefront-cart-title > .sft-products {
        width: 30%;
    }

    .storefront-navbar-options {
        display: none;
    }

    .userorder-crypto-expand-icon {
        display: block;
    }

    .storefront-index-hero-img {
        width: 67.5%;
    }

    .storefront-search-container {
        display: flex;
        flex-direction: column;
    }

    .storefront-input-container {
        width: 100%;
        margin-left: 0!important;
        margin-bottom: 12px;
    }

    .storefront-category-select {
        width: 100%;
        margin-left: 0;
        margin-top: 12px;
    }

    .storefront-items-row {
        flex-direction: column;
        margin-top: 0;
        width: 80%;
        margin-left: 10%;
    }

    .storefront-item {
        width: 100%;
        margin-left: 0;
        margin-top: 36px;
    }

    // .storefront {
    //     width: 90%;
    //     margin-left: 5%;
    // }

    .user-home-hero {
        flex-direction: column;
    }

    .userhome-hero-item {
        margin-left: 0;
        width: 100%;
        margin-top: 36px;
    }

    .user-home-welcome-title {
        font-size: 36px;
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .user-home-welcome-text {
        margin-top: 0;
    }

    .userorder-status {
        flex-direction: column;
        align-items: left;
    }

    .userorder-status-arrow {
        display: none;
    }

    .userorder-status-box {
        align-items: left;
        margin-top: 12px;
    }

    .userorder-data {
        flex-direction: column-reverse;
    }

    .userorder-data-other {
        margin-left: 0;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .userorder-content {
        width: 100%;
    }

    .user-home-content {
        margin-bottom: 32px;
    }

    .ReactModal__Content {
        width: 98%!important;
        margin-left: 1%!important;
    }

    .storefront-cart-pg {
        flex-direction: column;
    }

    .storefront-checkout {
        height: fit-content;
        border-radius: 16px;
    }

    .storefront-cart-pg {
        height: fit-content;
    }

    .storefront-cart {
        padding: 0;
    }

    .storefront-checkout {
        margin-top: 32px;
    }

    .storefront-navbar-right {
        display: flex;
        align-items: center;
    }

    .user-alert {
        flex-direction: column;
        margin-bottom: 0;
    }

    .user-alert-icon {
        display: none;
    }

    .user-alert button {
        margin-top: 12px;
        width: 100%;
    }

    .user-alert-content {
        margin-left: 0;
    }

    .storefront-navbar-mobile-toggle {
        display: flex;
    }

    .storefront-navbar-right {
        display: none;
    }

    .storefront-navbar-mobile-content {
        display: block;
    }

    .auth-form-page {
        height: fit-content;
        align-items: flex-start;
    }

    .userorder-crypto-view {
        flex-direction: column;
        height: fit-content;
        overflow: hidden;
    }

    .userorder-status-box {
        display: none;
    }

    .userorder-status-box-active {
        display: flex;
    }

    .userorder-crypto-view-image-container {
        margin-top: 60px;
        margin-bottom: 32px;
    }

    .userorder-crypto-view-content {
        padding: 28px;
        width: 100%;
        margin-left: 0;
    }

    .userorder-crypto-option-selected {
        border-bottom: none;
    }

    .userorder-crypto-option-title {
        font-size: 18px;
        margin-left: 6px;
        flex: 1;
    }

    .userorder-crypto-option {
        height: 74px;
    }

    .userorder-crypto-select {
        flex-direction: column;
        height: fit-content;
    }

    .userorder-crypto-option {
        display: none;
        justify-content: space-between;
    }

    .showMobile {
        display: flex;
    }

    .userorder-crypto-view-value-text {
        font-size: 16px;
    }

    .storefront-cart {
        width: 100%;
    }

    .storefront-checkout {
        width: 100%;
    }
}